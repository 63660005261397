import { Col, Row, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components/Card';
import { useModal } from '../../components/Modal/useModal';
import ScenariosFilter from './ScenariosFilter';
import { useScenarios } from './useScenarios';

import * as SC from '../../styles/common';
import * as S from './styles';

import StyledButton from '../../components/Common/StyledButton';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledModal from '../../components/Common/StyledModal';
import { Loading } from '../../components/Loading';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { RoutesPath } from '../../routes/routesPath';
import { BOMPage } from '../BOM';
import { ForecastsPage } from '../Forecasts';
import { PricesPage } from '../Prices';
import { Container } from './styles';

export function ScenariosPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.scenarios.title'));

  const history = useHistory();
  const {
    isLoading,
    dataUser,
    dataBase,
    fetchScenarios,
    clearFilter,
    searchFilter,
    copyScenario,
    deleteScenario,
    params,
  } = useScenarios();

  const [modalTitle, setModalTitle] = useState('');

  const [visibleBom, openModalBom, closeModalBom] = useModal();
  const [visibleForecasts, openModalForecasts, closeModalForecasts] = useModal();
  const [visiblePrices, openModalPrices, closeModalPrices] = useModal();

  const [dataModalBom, setDataModalBom] = useState({});
  const [dataModalForecasts, setDataModalForecasts] = useState({});
  const [dataModalPrices, setDataModalPrices] = useState({});

  const navigateToRegisterScenario = () => {
    history.push(RoutesPath.scenariosCreate);
  };

  const openModal = (area, type, dataScenario) => {
    localStorage.setItem('@scenarios-modal', JSON.stringify({ area, type, dataScenario }));

    const dataModal = {
      scenario: dataScenario?.id,
      targetYear: dataScenario?.targetYear,
      base: dataScenario?.base,
    };
    const isBaseScenario = !dataScenario?.base?.name;
    const prefixTitle = isBaseScenario ? 'Base' : 'User';

    setModalTitle(
      `${prefixTitle} ${t('pages.scenarios.titleSingular')}: ${dataScenario.targetYear} - ${
        dataScenario.name
      } - v${dataScenario.version}`
    );

    if (area === 'bom') {
      setDataModalBom(dataModal);
      openModalBom();
    } else if (area === 'forecasts') {
      setDataModalForecasts(dataModal);
      openModalForecasts();
    } else if (area === 'prices') {
      setDataModalPrices(dataModal);
      openModalPrices();
    }
  };

  const modalRef = useRef(null);
  const modalRef3 = useRef(null);
  const modalRef4 = useRef(null);

  useEffect(() => {
    fetchScenarios();
  }, [params]);

  // open last opened modal automatically
  useEffect(() => {
    const hasModalData = localStorage.getItem('@scenarios-modal');
    if (hasModalData) {
      const modalData = JSON.parse(hasModalData);
      openModal(modalData.area, modalData.type, modalData.dataScenario);
    }

    return () => {
      localStorage.removeItem('@scenarios-modal');
    };
  }, []);

  return (
    <main>
      {visibleBom && (
        <StyledModal
          title={modalTitle}
          ref={modalRef}
          open={visibleBom}
          onClose={closeModalBom}
          key="modal-bom"
          width="78rem"
          footer={null}
          body={<BOMPage view="scenarios" onClose={closeModalBom} dataModal={dataModalBom} />}
        />
      )}

      {visibleForecasts && (
        <StyledModal
          title={modalTitle}
          modalRef={modalRef3}
          open={visibleForecasts}
          onClose={closeModalForecasts}
          key="modal-forecasts"
          width="78rem"
          body={
            <ForecastsPage
              view="scenarios"
              onClose={closeModalForecasts}
              dataModal={dataModalForecasts}
            />
          }
          footer={null}
        />
      )}

      {visiblePrices && (
        <StyledModal
          title={modalTitle}
          modalRef={modalRef4}
          open={visiblePrices}
          onClose={closeModalPrices}
          key="modal-prices"
          width="78rem"
          footer={null}
          body={
            <PricesPage view="scenarios" onClose={closeModalPrices} dataModal={dataModalPrices} />
          }
        />
      )}

      <Row data-cy="scenarios-row">
        <ScenariosFilter
          clearFilter={clearFilter}
          searchFilter={searchFilter}
          values={params}
          title={t('pages.scenarios.title')}
        />

        {isLoading ? (
          <Loading />
        ) : (
          <Container span={23}>
            <Row justify="center">
              <Col span={23}>
                <StyledHeader
                  title={t('pages.scenarios.title')}
                  actions={
                    <StyledButton
                      data-cy="createScenario"
                      variant="primary"
                      iconLeft={<BiPlus />}
                      onClick={navigateToRegisterScenario}
                      text={t('pages.scenarios.register')}
                    />
                  }
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <Typography.Title level={5} style={{ color: '#89898b' }}>
                  {t('pages.scenarios.subtitle1')}
                </Typography.Title>
                <S.CardContainer>
                  {dataUser && dataUser.length ? (
                    dataUser.map((data) => (
                      <Card
                        key={data.id}
                        data={data}
                        type="user"
                        openModal={openModal}
                        copyScenario={copyScenario}
                        deleteScenario={deleteScenario}
                      />
                    ))
                  ) : (
                    <SC.NoData>
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    </SC.NoData>
                  )}
                </S.CardContainer>

                <br />
                <br />

                <Typography.Title level={5} style={{ color: '#89898b' }}>
                  {t('pages.scenarios.subtitle2')}
                </Typography.Title>
                <S.CardContainer>
                  {dataBase && dataBase.length ? (
                    dataBase.map((data) => (
                      <Card
                        key={data.id}
                        data={data}
                        type="base"
                        openModal={openModal}
                        deleteScenario={deleteScenario}
                      />
                    ))
                  ) : (
                    <SC.NoData>
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    </SC.NoData>
                  )}
                </S.CardContainer>
              </Col>
            </Row>
          </Container>
        )}
      </Row>
    </main>
  );
}
