import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';
import { ActionButton } from '../../components/ActionButton';

export const BackButton = styled(Button)`
  padding: 0;
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  .ant-tabs-nav::before {
    border-bottom: none;
  }
`;

export const ContentHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 2.25rem;
  border-bottom: ${({ isOpen, theme }) =>
    isOpen && `1px solid ${theme.colorsDesignSystem.tertiaryBorder}`};

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.tableValues};
`;

export const Title = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Value = styled.span<{ isBlue?: boolean; fontLG?: boolean }>`
  font-size: ${({ fontLG }) => (fontLG ? '1.25rem' : '1rem')};
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ isBlue, theme }) =>
    isBlue ? theme.colorsDesignSystem.blue : theme.colorsDesignSystem.text};
`;

export const ComponentsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  background: ${({ theme }) => theme.colorsDesignSystem.secondaryGrayBackground};
`;

export const HeaderContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;

  padding: 0.75rem 2.25rem;
  border-bottom: ${({ isOpen, theme }) =>
    isOpen && `1px solid ${theme.colorsDesignSystem.tertiaryBorder}`};

  img {
    width: 1rem;
    height: 1rem;
  }

  b {
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;

export const AltGroupContent = styled.div`
  padding: 1.5rem;

  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab .ant-tabs-tab-active,
  .ant-tabs-tab {
    text-align: center;
    width: max-content;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    padding: 0;
  }
`;

export const AltGroupCard = styled.div<{ project?: boolean; demand?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: ${({ project }) => project && 'center'};
  gap: 3.125rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
  border-radius: 0.375rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
`;

export const ClosedIcon = styled(CloseOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.link};
`;

export const StyledDropdown = styled(ActionButton)`
  span {
    padding: 0;
  }
`;

export const StatusIcons = styled(CloseCircleOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;

export const StatusTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;
